import axios from '@axios'
import useAppConfig from '@core/app-config/useAppConfig'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchData(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get(`${useAppConfig().AxiosUrl}api_mes/ctpp-mold/list`, { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    crudData(ctx, userData) {
      return new Promise((resolve, reject) => {
        axios
          .post(`${useAppConfig().AxiosUrl}api_mes/group-item-ctr/crud`, userData)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
